import React from 'react'
import { Link } from 'gatsby'

import Seo from 'components/seo'

import Breadcrumbs from '../components/Header/components/Breadcrumbs'

import Image404 from '../assets/images/page_404.svg'

const NotFoundPage = ({ data, location }) => {

  const breadcrumbsData = [
    { title: '404 | Error' },
  ];

  return (
    <section className="page_404">
      <div className="container">
        <div className="page_404__body">
          <div className="page_404__info">
            <Breadcrumbs items={breadcrumbsData} />
            <Seo title="404: Not Found" />
            <h1 className="page_404__title">
              404: Page not found
            </h1>
            <p className="page_404__text">
              Oops! That page can’t be found
            </p>
            <Link className="page_404__btn" to="/#">
              Back to homepage
            </Link>
          </div>
          <div className="page_404__image">
            <Image404 />
          </div>
        </div>
      </div>
    </section>
  )
}

export default NotFoundPage
